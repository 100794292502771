<template>
  <div class="radio_checkbox_preload">
      <div class="list_preload">
          <div class="item" v-for="item in props.items" :key="item">
              <div class="radio" :class="{'checkbox': props.type == 'checkbox'}">
              </div>
              <div class="box_preloader"></div>
          </div>
      </div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/radio-checkbox-preloader/RadioCheckboxPreloader.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/radio-checkbox-preloader/RadioCheckboxPreloader.scss" scoped>
</style>