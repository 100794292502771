<template>
  <div class="edit_project">
    <NavBar />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <ProjectTitle :editMode="true" @changeImageProject="changeImageProject"/>
    <div class="container">
      <div class="main-area">
        <div class="edit_project__form">
          <div class="edit_project__form_box">
            <h3 class="edit_project__form_title">プロジェクト編集</h3>
            <form class="horizontal">
              <div class="form-ctrl">
                <label class="text">プロジェクト名<span class="text-red">（必須）</span></label>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <Input
                        maxlength="50"
                        type="text"
                        placeholder="わかりやすいプロジェクト名を記入してください。"
                        v-model="values.title"
                        v-if="!state.preloader"
                      />
                      <InputPreloader v-if="state.preloader" />
                      <ErrorMessage
                        v-if="
                          checkErrorValidate('title', errors) &&
                          !state.preloader
                        "
                        :message="errors.title"
                      />
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.title
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage.title"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-form-ctrl">
                <div class="form-ctrl">
                  <label class="text">相談パターン<span class="text-red">（必須）</span>
<!--                    <PhasePopover />-->
                  </label>
                  <div class="row width-452">
                    <div class="col">
                      <div class="form-group">
                        <select
                          name=""
                          id=""
                          v-model="values.category_id"
                          :class="{ 'select-category': true, 'invalid': !values.category_id }"
                          v-if="!state.preloader"
                        >
                          <option value="" hidden>お選びください</option>
                          <option
                            v-for="category of state.projectCategories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.name }}
                          </option>
                        </select>
                        <InputPreloader v-if="state.preloader" />
                        <ErrorMessage
                          v-if="
                            checkErrorValidate('category_id', errors) &&
                            !state.preloader
                          "
                          :message="errors.category_id"
                        />
                        <template
                          v-if="
                            state.errorValidateMessage &&
                            state.errorValidateMessage.category_id
                          "
                        >
                          <ErrorMessage
                            v-for="message in state.errorValidateMessage
                              .category_id"
                            :message="message"
                            :key="message"
                          >
                          </ErrorMessage>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="form-ctrl">
                <label class="text height-27">相談概要<span class="text-red">（必須）</span></label>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <Textarea
                        placeholder="相談したいことをご入力ください"
                        :maxlength="10000"
                        v-model="values.description"
                        v-if="!state.preloader"
                      />
                      <InputPreloader v-if="state.preloader" type="textarea" />
                      <ErrorMessage
                        v-if="
                          checkErrorValidate('description', errors) &&
                          !state.preloader
                        "
                        :message="errors.description"
                      />
                      <template
                        v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.description
                        "
                      >
                        <ErrorMessage
                          v-for="message in state.errorValidateMessage
                            .description"
                          :message="message"
                          :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-form-ctrl">
                <div class="form-ctrl">
                  <label class="text direction-column">データタイプ<span class="text-red">※分かる場合のみ選択</span></label>
                  <div class="row width-452">
                    <div class="col">
                      <div class="form-checkbox-group">
                        <div class="checkbox" v-for="(dataType, i) in state.dataTypes" :key="i">
                          <input
                              type="checkbox"
                              :checked="values.data_types && 0 < values.data_types.filter(item => item.id === dataType.id).length"
                              :id="`data_type_${dataType.id}`"
                              @change="onChangeDataTypes($event, dataType)"
                          />
                          <label :for="`data_type_${dataType.id}`">{{ dataType.name }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-form-ctrl">
                <div class="form-ctrl">
                  <label class="text">対応可能な時間帯<span class="text-red">（必須）</span></label>
                  <div class="row width-452">
                    <div class="col">
                      <div class="form-checkbox-group">
                        <div class="checkbox" v-for="(availableDatetime, i) in state.availableDatetimes" :key="i">
                          <input
                              type="checkbox"
                              :checked="values.available_datetimes && 0 < values.available_datetimes.filter(item => item.id === availableDatetime.id).length"
                              :id="`available_datetime_${availableDatetime.id}`"
                              @change="onChangeAvailableDatetimes($event, availableDatetime)"
                          />
                          <label :for="`available_datetime_${availableDatetime.id}`">{{ availableDatetime.name }}</label>
                        </div>
                        <ErrorMessage
                            v-if="checkErrorValidate('available_datetimes', errors)"
                            :message="errors.available_datetimes"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
              <label class="text height-27">企業からのアピールポイント</label>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <Textarea
                      placeholder="企業から受験生へのアピールポイントをご入力ください"
                      :maxlength="10000"
                      v-model="values.appeal_point_from_company"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label class="text height-27">受験生に求めること</label>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <Textarea
                      placeholder="受験生に求めることをご入力ください"
                      :maxlength="10000"
                      v-model="values.expectation_for_student"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label class="text height-27">地域ハブ団体からの<br />アピールポイント</label>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <Textarea
                      placeholder="地域ハブ団体からのアピールポイントをご入力ください"
                      :maxlength="10000"
                      v-model="values.regional_hub_comment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-ctrl">
              <label class="text height-27">地域ハブ団体連絡先</label>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <Textarea
                      placeholder="団体名・氏名・連絡先(電話・メールアドレス　※受講生からの問合せを受けるもの)を記載ください"
                      :maxlength="100"
                      v-model="values.regional_hub_contact"
                      v-if="!state.preloader"
                    />
                    <InputPreloader v-if="state.preloader" type="textarea" />
                  </div>
                </div>
              </div>
            </div>
              <div class="form-ctrl">
                <label class="text">よくあるQ&A</label>
                <div class="row width-452">
                  <div class="col">
                    <div class="form-group">
                      <Textarea
                          placeholder="情報セキュリティの担保の仕方やファイルの受け渡し方法などを記載"
                          :maxlength="10000"
                          v-model="values.qa"
                          v-if="!state.preloader"
                      />
                      <InputPreloader v-if="state.preloader" type="textarea" />
                      <ErrorMessage
                          v-if="
                          checkErrorValidate('qa', errors) &&
                          !state.preloader
                        "
                          :message="errors.qa"
                      />
                      <template
                          v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.qa
                        "
                      >
                        <ErrorMessage
                            v-for="message in state.errorValidateMessage
                            .qa"
                            :message="message"
                            :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-ctrl">
                <label class="text">その他・連絡事項</label>
                <div class="row width-452">
                  <div class="col">
                    <div class="form-group">
                      <Textarea
                          placeholder="その他伝えたいこと"
                          :maxlength="10000"
                          v-model="values.note"
                          v-if="!state.preloader"
                      />
                      <InputPreloader v-if="state.preloader" type="textarea" />
                      <ErrorMessage
                          v-if="
                          checkErrorValidate('note', errors) &&
                          !state.preloader
                        "
                          :message="errors.note"
                      />
                      <template
                          v-if="
                          state.errorValidateMessage &&
                          state.errorValidateMessage.note
                        "
                      >
                        <ErrorMessage
                            v-for="message in state.errorValidateMessage
                            .note"
                            :message="message"
                            :key="message"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn_group">
                <button
                  :disabled="
                    state.loadingCreateProject || state.loadingSaveDraft
                  "
                  class="btn-md btn-light-gray shadow"
                  type="button"
                  @click="goBack"
                >
                  キャンセル
                </button>
                <button
                  :disabled="
                    state.loadingCreateProject || state.loadingSaveDraft
                  "
                  :class="{ submitting: state.loadingSaveDraft }"
                  type="button"
                  class="btn-md btn-blue shadow"
                  @click="saveDraft"
                  v-if="state.progress_status == 'temp_saved' || state.progress_status == 'unapproved' || state.progress_status == 'rejected'"
                  >
                  <i
                    v-if="state.loadingSaveDraft"
                    class="fas fa-circle-notch fa-spin"
                  ></i
                  >一時保存する
                </button>
                <button
                  :disabled="
                    state.loadingCreateProject || state.loadingSaveDraft
                  "
                  :class="{ submitting: state.loadingCreateProject }"
                  type="button"
                  class="btn-md btn-blue shadow"
                  @click="updateProject"
                >
                  <i
                    v-if="state.loadingCreateProject"
                    class="fas fa-circle-notch fa-spin"
                  ></i
                  >登録内容確認
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/organization/my-profile/edit-project/EditProject.ts" scoped>
</script>

<style lang="scss" src="@/presentation/views/organization/my-profile/edit-project/EditProject.scss" scoped>
</style>
