import { __awaiter, __generator } from "tslib";
import { reactive, defineComponent, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import Project from "@/domain/entity/Project";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import routePath from "@/commons/RoutePath";
import { useStore } from "vuex";
import ProjectTitle from "@/presentation/components/project-title/ProjectTitle.vue";
import InputPreloader from "@/presentation/components/preloader/input-preloader/InputPreloader.vue";
import RadioCheckboxPreloader from "@/presentation/components/preloader/radio-checkbox-preloader/RadioCheckboxPreloader.vue";
import DatePicker from "@/presentation/components/datepicker/DatePicker.vue";
import CurrencyInput from "@/presentation/components/currency-input/CurrencyInput.vue";
import * as agh from "agh.sprintf";
import Input from "@/presentation/components/input/Input.vue";
import PhasePopover from "@/presentation/components/popover/phase-popover/PhasePopover.vue";
import { orderBy } from 'lodash';
export default defineComponent({
    name: "EditProject",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        ProjectTitle: ProjectTitle,
        InputPreloader: InputPreloader,
        RadioCheckboxPreloader: RadioCheckboxPreloader,
        DatePicker: DatePicker,
        CurrencyInput: CurrencyInput,
        Input: Input,
        PhasePopover: PhasePopover
    },
    setup: function () {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var _a = useForm({
            initialValues: {
                project_id: null,
                title: "",
                description: "",
                category_id: "",
                appeal_point_from_company: "",
                expectation_for_student: "",
                regional_hub_comment: "",
                regional_hub_contact: "",
                qa: "",
                note: "",
                image_file_name: "",
                progress_status: "",
                data_types: [],
                available_datetimes: [],
            }
        }), setValues = _a.setValues, setFieldValue = _a.setFieldValue, values = _a.values, isSubmitting = _a.isSubmitting, errors = _a.errors, setErrors = _a.setErrors, handleSubmit = _a.handleSubmit;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: "/organization/profile/recruiting",
                    name: "プロジェクト一覧"
                },
                {
                    route: "/organization/profile/recruiting",
                    name: "プロジェクトカテゴリ"
                },
                {
                    route: "",
                    name: ""
                }
            ],
            projectCategories: [],
            loadingSaveDraft: false,
            loadingCreateProject: false,
            errorValidateMessage: [],
            serverError: null,
            preloader: true,
            progress_status: "",
            prefectures: [],
            cities: [],
            dataTypes: [],
            availableDatetimes: [],
        });
        // 404処理
        watch(function () { return store.state.projectInfo; }, function (data) {
            // 募集中・募集終了・実施中・完了の時は見れない
            if (data.progress_status === 'created' ||
                data.progress_status === 'recruiting' ||
                data.progress_status === 'recruiting_expired' ||
                data.progress_status === 'finish') {
                router.push(routePath.PAGE_404);
            }
        });
        var numberRegex = /^\d*$/;
        // validate
        var title = useField("title", yup
            .string()
            .required(state.aLang.validation_required)
            .min(3, agh.sprintf(state.aLang.validation_min_length, 3))
            .max(50, agh.sprintf(state.aLang.validation_max_length, 50))).value;
        var description = useField("description", yup
            .string()
            .required(state.aLang.validation_required)
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var category_id = useField("category_id", yup
            .string()
            .required(state.aLang.validation_required)).value;
        var available_datetimes = useField("available_datetimes", yup
            .array()
            .required(state.aLang.validation_required)).value;
        var qa = useField("qa", yup
            .string()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var note = useField("note", yup
            .string()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        function convertDatetime(date) {
            if (!date) {
                return "";
            }
            var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('-');
        }
        function checkErrorValidate(field, error) {
            if (!error[field]) {
                return false;
            }
            if (state.errorValidateMessage[field]) {
                state.errorValidateMessage[field] = [];
            }
            return true;
        }
        function onChangeDataTypes(event, dataType) {
            var id = parseInt(dataType.id);
            values.data_types = values.data_types ? values.data_types.filter(function (item) { return item.id !== id; }) : [];
            if (event.currentTarget.checked) {
                values.data_types.push(dataType);
            }
        }
        function onChangeAvailableDatetimes(event, availableDatetime) {
            var id = parseInt(availableDatetime.id);
            var newDatetimes = values.available_datetimes ? values.available_datetimes.filter(function (item) { return item.id !== id; }) : [];
            if (event.currentTarget.checked) {
                newDatetimes.push(availableDatetime);
            }
            values.available_datetimes = orderBy(newDatetimes, ['id'], ['asc']);
        }
        var saveDraft = handleSubmit(function () {
            state.loadingSaveDraft = true;
            var data = new Project;
            data = Object.assign(data, values);
            data.progress_status = "TEMP_SAVED";
            organizationProjectInteractor.updateOrganizationProject(values.project_id, data).then(function (result) {
                state.loadingSaveDraft = false;
                store.commit("setProjectTempSaved", result.data.id);
                router.push(routePath.PROJECT_DETAIL + result.data.id);
            }).catch(function (error) {
                state.loadingSaveDraft = false;
                if (error && error.validation_errors) {
                    Object.assign(state.errorValidateMessage, error.validation_errors);
                }
                if (error && error.message) {
                    state.serverError = error.message;
                }
            });
        });
        var updateProject = handleSubmit(function () {
            state.loadingCreateProject = true;
            var data = new Project;
            data = Object.assign(data, values);
            store.commit("setOrganizationProjectData", data);
            state.loadingCreateProject = false;
            return router.push(routePath.ORGANIZATION_CONFIRM_PROJECT);
        });
        function goBack() {
            return router.push(routePath.ORGANIZATION_DASHBOARD);
        }
        function processData(data) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_q) {
                    state.progress_status = (_a = data.progress_status) !== null && _a !== void 0 ? _a : "";
                    setValues({
                        project_id: (_b = data.project_id) !== null && _b !== void 0 ? _b : null,
                        title: (_c = data.title) !== null && _c !== void 0 ? _c : "",
                        description: (_d = data.description) !== null && _d !== void 0 ? _d : "",
                        category_id: data.category && data.category.id ? data.category.id : "",
                        appeal_point_from_company: (_e = data.appeal_point_from_company) !== null && _e !== void 0 ? _e : "",
                        expectation_for_student: (_f = data.expectation_for_student) !== null && _f !== void 0 ? _f : "",
                        regional_hub_comment: (_g = data.regional_hub_comment) !== null && _g !== void 0 ? _g : "",
                        regional_hub_contact: (_h = data.regional_hub_contact) !== null && _h !== void 0 ? _h : "",
                        qa: (_j = data.qa) !== null && _j !== void 0 ? _j : "",
                        note: (_k = data.note) !== null && _k !== void 0 ? _k : "",
                        image_file_name: (_l = data.image_file_name) !== null && _l !== void 0 ? _l : "",
                        progress_status: (_m = data.progress_status) !== null && _m !== void 0 ? _m : "",
                        data_types: (_o = data.data_types) !== null && _o !== void 0 ? _o : [],
                        available_datetimes: (_p = data.available_datetimes) !== null && _p !== void 0 ? _p : [],
                    });
                    setTimeout(function () {
                        setErrors({
                            project_id: undefined,
                            title: undefined,
                            description: undefined,
                            category_id: undefined,
                            qa: undefined,
                            note: undefined,
                            image_file_name: undefined,
                            progress_status: undefined,
                        });
                        state.preloader = false;
                    }, 500);
                    return [2 /*return*/];
                });
            });
        }
        function getProjectData() {
            state.preloader = true;
            var project_id = route.params.projectId;
            var organizationProjectData = store.state.organizationProjectData;
            if (organizationProjectData.title) {
                state.preloader = false;
                var dataTitle = JSON.parse(JSON.stringify(organizationProjectData));
                store.commit("setProjectInfo", dataTitle);
                setValues(organizationProjectData);
                state.progress_status = organizationProjectData.progress_status;
            }
            else {
                organizationProjectInteractor.getOrganizationProjectById(project_id).then(function (result) {
                    var _a;
                    if (!result.data) {
                        state.preloader = false;
                        store.commit('setProjectInfo', {});
                        return;
                    }
                    values.data_types = result.data.data_types;
                    values.available_datetimes = result.data.available_datetimes;
                    store.commit('setProjectInfo', result.data);
                    processData(result.data);
                    state.listBreadcrumb[2].name = (_a = result.data.title) !== null && _a !== void 0 ? _a : "";
                }).catch(function (err) {
                    store.commit('setProjectInfo', {});
                    router.push(routePath.PAGE_404);
                });
            }
        }
        function initData() {
            Promise.all([
                projectInteractor.getProjectCategories().then(function (result) {
                    state.projectCategories = result.data;
                }),
                masterInteractor.getAllPerfecture().then(function (result) {
                    state.prefectures = result.data;
                }),
                masterInteractor.getDataTypes().then(function (result) {
                    state.dataTypes = result.data;
                }),
                masterInteractor.getAvailableDatetimes().then(function (result) {
                    state.availableDatetimes = result.data;
                }),
            ]).then(getProjectData);
        }
        function changeImageProject(e) {
            setValues({
                image_file_name: e
            });
        }
        function checkExistCities(project_place_city_id) {
            var cities = state.cities.findIndex(function (o) { return o.id == project_place_city_id; });
            return cities != -1 ? true : false;
        }
        onMounted(function () {
            initData();
        });
        router.beforeEach(function (to, from, next) {
            if ((from.name == "OrganizationDashboardCreateProject" && to.path != routePath.ORGANIZATION_CONFIRM_PROJECT) ||
                (from.name == "OrganizationDashboardConfirmProject" && to.name != "OrganizationDashboardCreateProject" && to.name != "OrganizationProjectEdit") ||
                (from.name == "OrganizationProjectEdit" && to.path != routePath.ORGANIZATION_CONFIRM_PROJECT)) {
                store.commit("setOrganizationProjectData", {});
                store.commit('setProjectInfo', {});
            }
            next();
        });
        return {
            state: state,
            values: values,
            errors: errors,
            isSubmitting: isSubmitting,
            saveDraft: saveDraft,
            updateProject: updateProject,
            goBack: goBack,
            checkErrorValidate: checkErrorValidate,
            changeImageProject: changeImageProject,
            onChangeDataTypes: onChangeDataTypes,
            onChangeAvailableDatetimes: onChangeAvailableDatetimes,
        };
    }
});
