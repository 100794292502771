<template>
  <div class="input_preload">
      <div class="box_preloader" :class="{'textarea': props.type == 'textarea'}"></div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/input-preloader/InputPreloader.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/input-preloader/InputPreloader.scss" scoped>
</style>